import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.PROFILE.TEXT',
        icon: 'bx-user-circle',
        link: '/profile',
    },
    {
        id: 3,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        subItems: [
            {
                id: 4,
                label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
                link: '/default',
                parentId: 3
            }
        ]
    },
    {
        id: 5,
        label: 'MENUITEMS.TERRITORIES.TEXT',
        icon: 'bx-map',
        link: '/territory/list'
    },
    {
        id: 6,
        label: 'MENUITEMS.PLACES.TEXT',
        icon: 'bx-task',
        link: '/place/list',
    },
    {
        id: 7,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'bx-body',
        link: '/user/list'
    },
    {
        id: 8,
        label: 'MENUITEMS.TEAMS.TEXT',
        icon: 'bxs-group',
        link: '/team/list'
    },
    {
        id: 9,
        label: 'MENUITEMS.CUSTOMERS.TEXT',
        icon: 'bxs-user-detail',
        link: '/customer/list'
    },
    {
        id: 10,
        label: 'MENUITEMS.CATEGORIES.TEXT',
        icon: 'bx-receipt',
        link: '/category/list'
    },
    {
        id: 11,
        label: 'MENUITEMS.PRODUCTS.TEXT',
        icon: 'bx-store',
        link: '/product/list'
    },
    {
        id: 12,
        label: 'MENUITEMS.PRICELISTS.TEXT',
        icon: 'bx-task',
        link: '/pricelist/list'
    },
    {
        id: 13,
        isLayout: true
    },
    {
        id: 14,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true
    },
    {
        id: 15,
        label: 'MENUITEMS.TASKS.TEXT',
        icon: 'bx-task',
        link: '/tasks/list'
    },
    {
        id: 16,
        label: 'MENUITEMS.EVENTS.TEXT',
        icon: 'bx-calendar',
        link: '/calendar',
    },
    {
        id: 17,
        label: 'MENUITEMS.CHAT.TEXT',
        icon: 'bx-chat',
        link: '/chat',
        badge: {
            variant: 'success',
            text: 'MENUITEMS.CHAT.BADGE',
        },
    },
    {
        id: 18,
        label: 'MENUITEMS.INVOICES.TEXT',
        icon: 'bx-receipt',
        subItems: [
            {
                id: 19,
                label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
                link: '/invoices/list',
                parentId: 18
            },
            {
                id: 20,
                label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
                link: '/invoices/detail',
                parentId: 18
            },
        ]
    },
    {
        id: 19,
        label: 'MENUITEMS.CONTACTS.TEXT',
        icon: 'bxs-user-detail',
        subItems: [
            {
                id: 20,
                label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
                link: '/contacts/grid',
                parentId: 19
            },
            {
                id: 21,
                label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
                link: '/contacts/list',
                parentId: 19
            }
        ]
    },
    {
        id: 22,
        label: 'MENUITEMS.MAPS.TEXT',
        icon: 'bx-map',
        subItems: [
            {
                id: 23,
                label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
                link: '/maps/google',
                parentId: 22
            }
        ]
    }
];

