import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private toastr: ToastrService
    ) {}

  displayMessage(message: string, type: string = 'Warning', title: string = '') {
    switch (type) {
      case 'Success':
        this.toastr.success(message, title);
        break;

      case 'Error':
        this.toastr.error(message, title);
        break;

      case 'Info':
        this.toastr.info(message, title);
        break;

      default:
        this.toastr.warning(message, title);
        break;
    }
  }
}
