import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataService } from './core/services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  location: Location;
  constructor(private dataService: DataService) { }

  ngOnInit() {
    if (location.protocol.includes('http:')) {
      window.location.href = location.href.replace('http', 'https');
    }
  }

  ngAfterViewInit() {
    this.dataService.connect();
  }
}
