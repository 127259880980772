import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class AutharangoService {

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    var localUser = localStorage.getItem('currentUser') === null ? null : JSON.parse(atob(localStorage.getItem('currentUser')));
    this.currentUserSubject = new BehaviorSubject<User>(localUser);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(user: User) {
    localStorage.setItem('currentUser', btoa(JSON.stringify(user)));
    this.currentUserSubject.next(user);
    return user;
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
