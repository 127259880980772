import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.PROFILE.TEXT',
        icon: 'bx-user-circle',
        link: '/profile',
    },
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        subItems: [
            {
                id: 2,
                label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
                link: '/default',
                parentId: 1
            }
        ]
    },
    {
        id: 94,
        label: 'HEADER.PAGES.TITLE',
        icon: 'bx-file',
        subItems: [
            {
                id: 5,
                label: 'MENUITEMS.TERRITORIES.TEXT',
                icon: 'bx-map',
                link: '/territory/list'
            },
            {
                id: 6,
                label: 'MENUITEMS.PLACES.TEXT',
                icon: 'bx-task',
                link: '/place/list',
            },
            {
                id: 7,
                label: 'MENUITEMS.USERS.TEXT',
                icon: 'bx-body',
                link: '/user/list'
            },
            {
                id: 8,
                label: 'MENUITEMS.TEAMS.TEXT',
                icon: 'bxs-group',
                link: '/team/list'
            },
            {
                id: 9,
                label: 'MENUITEMS.CUSTOMERS.TEXT',
                icon: 'bxs-user-detail',
                link: '/customer/list'
            },
            {
                id: 10,
                label: 'MENUITEMS.CATEGORIES.TEXT',
                icon: 'bx-receipt',
                link: '/category/list'
            },
            {
                id: 11,
                label: 'MENUITEMS.PRODUCTS.TEXT',
                icon: 'bx-store',
                link: '/product/list'
            },
            {
                id: 12,
                label: 'MENUITEMS.PRICELISTS.TEXT',
                icon: 'bx-task',
                link: '/pricelist/list'
            }
        ]
    },
    {
        id: 24,
        label: 'MENUITEMS.APPS.TEXT',
        icon: 'bx-customize',
        subItems: [
            {
                id: 15,
                label: 'MENUITEMS.TASKS.TEXT',
                icon: 'bx-task',
                link: '/tasks/list'
            },
            {
                id: 25,
                label: 'MENUITEMS.EVENTS.TEXT',
                link: '/calendar',
                parentId: 24
            },
            {
                id: 26,
                label: 'MENUITEMS.CHAT.TEXT',
                link: '/chat',
                parentId: 24
            },
            {
                id: 95,
                label: 'MENUITEMS.INVOICES.TEXT',
                subItems: [
                    {
                        id: 96,
                        label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
                        link: '/invoices/list',
                        parentId: 95
                    },
                    {
                        id: 97,
                        label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
                        link: '/invoices/detail',
                        parentId: 95
                    },
                ]
            },
            {
                id: 61,
                label: 'MENUITEMS.CONTACTS.TEXT',
                icon: 'bxs-user-detail',
                subItems: [
                    {
                        id: 62,
                        label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
                        link: '/contacts/grid',
                        parentId: 61
                    },
                    {
                        id: 63,
                        label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
                        link: '/contacts/list',
                        parentId: 61
                    },
                    {
                        id: 64,
                        label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
                        link: '/contacts/profile',
                        parentId: 61
                    }
                ]
            },
            {
                id: 22,
                label: 'MENUITEMS.MAPS.TEXT',
                icon: 'bx-map',
                subItems: [
                    {
                        id: 23,
                        label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
                        link: '/maps/google',
                        parentId: 22
                    }
                ]
            }
        ]
    }
];

