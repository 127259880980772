<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                © {{year}} Instore. | <a class="text-primary">Terms of Use</a> | <a class="text-darker">Privacy Policy</a>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-right d-none d-sm-block">
                    <p>Crafted with <i class="mdi mdi-heart text-danger"></i> by Kasibrothers</p>
                </div>
            </div>
        </div>
    </div>
</footer>