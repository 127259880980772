import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// services
import { WebsocketService } from './websocket.service';
import { NotificationService } from '../../../app/core/services/notification.service';

// models
import { Category } from '../../../app/shared/models/category.model';
import { Customers } from '../../../app/shared/models/customers.model';
import { Place } from '../../../app/shared/models/place.model';
import { Pricelist } from '../../../app/shared/models/pricelist.model';
import { Product } from '../../../app/shared/models/products.model';
import { Task, TaskDetails} from '../../../app/shared/models/task.model';
import { Team } from '../../../app/shared/models/team.model';
import { Territory } from '../../../app/shared/models/territory.model';
import { Users } from '../../../app/shared/models/users.model';
import { Chat } from '../../../app/shared/models/chat.model';
import { EventInput } from '@fullcalendar/core';
import { StatData, UserTaskList } from '../../../app/shared/models/profile.model'



@Injectable({
  providedIn: 'root'
})
export class DataService {
  public messages: Subject<any>;
  public responses: Subject<any> = new Subject<any>();

  public products: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public territories: BehaviorSubject<Territory[]> = new BehaviorSubject([]);
  public categores: BehaviorSubject<Category[]> = new BehaviorSubject([]);
  public pricelists: BehaviorSubject<Pricelist[]> = new BehaviorSubject([]);
  public places: BehaviorSubject<Place[]> = new BehaviorSubject([]);
  public tasks: BehaviorSubject<Task[]> = new BehaviorSubject([]);
  public completedTasks: BehaviorSubject<TaskDetails[]> = new BehaviorSubject([]);
  public users: BehaviorSubject<Users[]> = new BehaviorSubject([]);
  public user: BehaviorSubject<Users> = new BehaviorSubject({} as Users);
  public teams: BehaviorSubject<Team[]> = new BehaviorSubject([]);
  public chats: BehaviorSubject<any> = new BehaviorSubject({});
  public customers: BehaviorSubject<Customers[]> = new BehaviorSubject([]);
  public events: BehaviorSubject<EventInput[]> = new BehaviorSubject([]);
  public statData: BehaviorSubject<StatData[]> = new BehaviorSubject([]);
  public userTaskList: BehaviorSubject<UserTaskList[]> = new BehaviorSubject([]);

  get getProducts(): Observable<Product[]> { return this.products.asObservable(); }
  get getTerritories(): Observable<Territory[]> { return this.territories.asObservable(); }
  get getCategories(): Observable<Category[]> { return this.categores.asObservable(); }
  get getPricelists(): Observable<Pricelist[]> { return this.pricelists.asObservable(); }
  get getPlaces(): Observable<Place[]> { return this.places.asObservable(); }
  get getTasks(): Observable<Task[]> { return this.tasks.asObservable(); }
  get getCompletedTask(): Observable<TaskDetails[]> { return this.completedTasks.asObservable(); }
  get getUsers(): Observable<Users[]> { return this.users.asObservable(); }
  get getUser(): Observable<Users> { return this.user.asObservable(); }
  get getTeams(): Observable<Team[]> { return this.teams.asObservable(); }
  get getCustomers(): Observable<Customers[]> { return this.customers.asObservable(); }
  get getEvents(): Observable<EventInput[]> { return this.events.asObservable(); }
  get getChats(): Observable<any> { return this.chats.asObservable(); }
  get getStatsData(): Observable<any> { return this.statData.asObservable(); }
  get getUserTaskList(): Observable<any> { return this.userTaskList.asObservable(); }

  private REST_API_SERVER = 'http://instore.sipnet.co.za/';

  constructor(
    private httpClient: HttpClient,
    private wsService: WebsocketService,
    private notificationService: NotificationService
  ) { }

  public connect() {
    this.messages = ((
      this.wsService.connect().map((response: MessageEvent): any => {
        try {
          const data = JSON.parse(response.data);
          return data;
        } catch (error) {
          return response.data;
        }
      })
    ) as Subject<any>);

    this.processData();
  }

  public close() {
    this.wsService.close();
  }

  private processData() {
    this.messages.subscribe(res => {
      console.log(res)
      switch (res.action) {
        case 'GETPRODUCTS':
          this.products.next(this.sortData(res.data, 'productname'));
          break;

        case 'GETCLIENTTERRITORIES':
          this.territories.next(this.sortData(res.data, 'name'));
          break;

        case 'GETCATEGORIES':
          this.categores.next(this.sortData(res.data, 'categoryname'));
          break;

        case 'GETALLPRICELIST':
          this.pricelists.next(this.sortData(res.data, 'pricelistname'));
          break;

        case 'GETOUTLETS':
          res.data.forEach((outlet) => {
            res.data.suburb = Array.isArray(outlet.suburb)
              ? outlet.suburb
              : [outlet.suburb];
          });
          this.places.next(this.sortData(res.data, 'suburb'));
          break;

        case 'GETTASKLIST':
          this.tasks.next(this.sortData(res.data, 'taskname'));
          console.log(this.tasks)
          break;
        case 'GETCOMPLETEDTASKDATA':
          this.completedTasks.next(this.sortData(res.data, 'tasklistid'));
          console.log(this.completedTasks)
          break;

        case 'GETUSERS':
          res.data.forEach((user) => {
            res.data.isblocked = user.isblocked === 0 ? false : true;
          });
          const data = res.data;//.filter((e) => e.status !== 'InActive');
          this.users.next(this.sortData(data, 'firstname'));
          break;

        case 'GETUSER':
          this.user.next(res.data);
          break;

        case 'GETCHATS':
          if (res.data.length > 0) {
            this.chats.next(this.sortChatMsgs(this.sortByDate(res.data)));
          }
          break;

        case 'GETALLTEAMLISTS':
          this.teams.next(this.sortData(res.data, 'teamname'));
          break;

        case 'GETEVENTS':
          this.events.next(this.sortData(res.data, 'title'));
          break;

        case 'GETCUSTOMER':
          res.data.forEach((user) => {
            res.data.isblocked = user.isblocked === 0 ? false : true;
          });
          this.customers.next(this.sortData(res.data, 'name'));
          break;
        case 'GETTASKDATA':
          console.log(res.data)
          if (res.data.length > 0) {
            this.statData.next(this.sortData(res.data, 'taskdate'));
          }
          break
        case 'GETTOPTENTASKS':
          if (res.data.length > 0) {
            this.userTaskList.next(this.sortData(res.data, 'taskDate'));
          }
          break

        default:
          var msg = res.data === undefined ? res : res.data;
          if (typeof msg === 'string' && !!msg) {
            this.alert(msg);
            this.responses.next(res);
          }
      }
    });
  }

  private alert(message) {
    if (
      message.toLowerCase().includes('not') ||
      message.toLowerCase().includes('block') ||
      message.toLowerCase().includes('error') ||
      message.toLowerCase().includes('already') ||
      message.toLowerCase().includes('failed') ||
      message.toLowerCase().includes('session')
    ) {
      this.notificationService.displayMessage(message, 'Error');
    } else if (message.toLowerCase().includes('invalid')) {
      this.notificationService.displayMessage(message, 'Warning');
    }
    else if (message.toLowerCase().includes('pending') ||
      message.toLowerCase().includes('confirm ')) {
      this.notificationService.displayMessage(message, 'Info');
    } else {
      this.notificationService.displayMessage(message, 'Success');
    }
  }

  private sortData(data: any, para: any) {
    data.sort((a, b) =>
      a[para]?.toLowerCase() < b[para]?.toLowerCase() ? -1 : 1
    );
    return data;
  }

  private sortByDate(data: any) {
    data.sort((x, y) =>
      new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
    );
    return data;
  }

  private sortChatMsgs(data: any) {
    var groups = {};

    data.forEach(function (val) {
      let time = new Date(Date.parse(val.timestamp));
      let date = time.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' });
      if (date in groups) {
        groups[date].push(val);
      } else {
        groups[date] = new Array(val);
      }
    });
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        chats: groups[date]
      };
    });

    return groupArrays;
  }
}
