import { Injectable } from "@angular/core";
import * as Rx from "rxjs/Rx";

@Injectable({
  providedIn: "root"
})
export class WebsocketService {

  url = 'wss://app.instoreonline.co.za:4333'; // 'ws://127.0.0.1:3333/'; 'ws://156.38.207.162:3333/'

  constructor() { }

  private ws: any;
  private subject: Rx.Subject<MessageEvent>;

  public connect(): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(this.url);
      const currentDate = new Date();
      console.log(
        currentDate.toLocaleString() + '\nSuccessfully connected: ' + this.url
      );
    }
    return this.subject;
  }

  public close() {
    this.ws.close();
    this.subject = null;
    console.log('Closed connection');
  }

  private create(url): Rx.Subject<MessageEvent> {
    this.ws = new WebSocket(url);

    const observable = Rx.Observable.create(
      (obs: Rx.Observer<MessageEvent>) => {
        this.ws.onmessage = obs.next.bind(obs);
        this.ws.onerror = obs.error.bind(obs);
        this.ws.onclose = obs.complete.bind(obs);
        return this.ws.close.bind(this.ws);
      }
    ).share();

    const observer = {
      next: (data: object) => {
        setTimeout(() => {
          if (this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(data));
          } else if (this.ws.readyState === WebSocket.CLOSED) {
            window.location.reload();
          } else {
            observer.next(data);
          }
        }, 5);
      }
    };
    return Rx.Subject.create(observer, observable);
  }
}
