import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { PasswordMatchValidator } from 'src/app/shared/validators/password-match-validator';

// services
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { AutharangoService } from '../../core/services/autharango.service';
import { User } from '../../core/models/auth.models';
import { DataService } from '../../core/services/data.service';

// models
import { Users } from '../../shared/models/users.model';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  valueset;
  cookieValue;
  flagvalue;
  countryName;
  currentUser: User;
  submitted: boolean;
  displayname: string;
  fieldTextType: boolean;
  fieldTextType2: boolean;
  userData: Users = <Users>{};
  newpasswordForm: FormGroup;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private authArangoService: AutharangoService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private loader: LoaderService,) {
    this.currentUser = authArangoService.currentUserValue;
  }

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.getGetUserData();
    this.clearFormInputs();
  }

  get f() { return this.newpasswordForm.controls; }

  private clearFormInputs() {
    this.newpasswordForm = this.formBuilder.group({
      password: ['',
        [
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            CustomValidators.patternValidator(/[!_@#$\^%&*()_+{}:"<>?|]/, { hasSpecialCharacters: true })
          ])
        ]
      ],
      confirmpassword: ['', [Validators.required]],
    },
      {
        validator: PasswordMatchValidator.passwordMatchValidator

      });
    this.submitted = false;
  }

  private getGetUserData() {
    const data: any = {};
    data.action = 'GETUSER';
    this.processData();
    this.sendMsg(data);
  }

  private sendMsg(message) {
    message.userid = this.currentUser.id;
    message.clientid = this.currentUser.clientid;
    message.token = this.currentUser.token;
    this.dataService.messages.next(message);
  }

  private processData() {
    this.dataService.getUser.subscribe(response => {
      this.userData = response;
      this.displayname = response.nickname !== '' ? response.nickname : response.firstname;
    });
    this.dataService.messages.subscribe(response => {
      if ((response.action === 'UPDATEPASSWORD')) {
        this.loader.isLoading.next(false);
        this.submitted = false;
        this.newpasswordForm.reset();
      }
    });
  }

  private openModal(content: any) {
    this.modalService.open(content, { centered: true });
  }


  public showImage(imageUrl: string) {
    return imageUrl === '' ? 'assets/images/no-image.jpg' : imageUrl;
  }

  public changePassword(content: any) {
    this.clearFormInputs();
    this.openModal(content);
  }



  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.newpasswordForm.invalid) {
      return;
    }
    this.loader.isLoading.next(true);
    const data = { action: 'UPDATEPASSWORD', password: this.f.password.value };
    this.sendMsg(data);
  }
}
